import React from 'react';
import MainLayout from '../../layouts/mainLayout';
import { OptimizedImage } from '../../components/common/OptimizedImage';

export default () => (
  <MainLayout withTitle>
    <div className="faq-wrapper">
      <div className="faq-content-wrapper">
        <div className="hdr text-left">
          <h2 className="mgn-bot-30">Ship Today Order</h2>
          <p className="qnA-text">
            If you have run out of food (or almost have) and need to order food
            quickly - a 'ship today' order is the best option. If you are
            running out of food quickly you may want to reduce the time between
            orders and if you are finding that you are left with lots of food
            you can extend this time. It may take a bit of adjusting to get just
            right but soon it will be perfect for your Furbaby!
          </p>
          <p className="mgn-top-30 qnA-text">
            1. Log in to your account and view your Dashboard <br />
            2. Click on "Reorder Now" <br />
            3. Place a "Ship Today" order
          </p>
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="login.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="shipToday_step_2.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="shipToday_step_3.png" />
        </div>
        <p className="qnA-text">
          If you didn't find the answer you were looking for please contact us,
          we would love to help! <br />
          Email: info@feedmyfurbaby.co.nz <br />
          Phone: 09 88 MYPET (09 886 9738) <br />
          Facebook: @myfurbabynz <br />
          Or contact us using our webchat.
        </p>
      </div>
    </div>
  </MainLayout>
);
